import { View, Text, ScrollView, LayoutChangeEvent, RefreshControl } from 'react-native'
import React, { RefObject, useContext, useEffect, useRef, useState } from 'react'

import ContactDetailsRow from './components/ContactDetailsRow'
import ExpandableText from 'src/components/ExpandableText'
import InfoRow from './components/InfoRow'
import OfferHeader from './components/OfferHeader'
import OfferInfo from './components/OfferInfo'
import SchoolNavigationRow from './components/SchoolNavigationRow'
import translations, { translate } from 'src/utils/translations/translations'
import {
  AssignmentIndIcon,
  FreeBreakfastRoundedIcon,
  HouseRoundedIcon,
  PaymentsIcon,
  PersonRoundedIcon,
  PsychologyRoundedIcon,
  RestaurantRoundedIcon,
  SlideShowIcon,
} from 'src/icons'
import { JobDetailsBookingProps, JobDetailsExpressProps } from './types'
import styles from './styles'
import DownloadDocument from 'src/components/DownloadDocument'
import BookingsList from './components/BookingsList/BookingsList'
import AlertCard, { alertColorScheme, alertVariants } from 'src/components/AlertCard'
import { colors, colorsNew } from 'src/styles'
import RedirectRow from 'src/components/RedirectRow'
import { openURLIfCan } from 'src/global/utils'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { northpassUrls } from 'src/global/constants'
import { TranslationGBT } from 'src/utils/translations/i18nTypes'
import globalStyles from 'src/global/globalStyles'

type Props = (JobDetailsBookingProps | JobDetailsExpressProps) & { onOpen?: () => any }

const JobDetails = ({
  bookingsList,
  children,
  jobHeader,
  jobInformation,
  jobLevel,
  jobTitle,
  loading,
  onOpen,
  payRate,
  refetch,
  showMask,
  ...props
}: Props) => {
  const {
    competences,
    healthInformation,
    isNorthpassButtonDisplay,
    jobFiles,
    jobGrade,
    jobRoles,
    level,
    lunchPrice,
    originalTeacher,
    pupilFiles,
    pupilInfo,
    room,
    schoolData,
    SENSkills,
    type,
    unpaidBreakTime,
  } = props as JobDetailsBookingProps

  useEffect(() => {
    if (onOpen) onOpen()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [bookingListPosition, setBookingListPosition] = useState<number | null>(null)
  const scrollRef = useRef() as RefObject<ScrollView>
  const { mixpanel } = useContext(MixpanelContext)

  const scrollToBookings =
    scrollRef.current && bookingListPosition
      ? () => scrollRef.current?.scrollTo({ x: 0, y: bookingListPosition, animated: true })
      : undefined

  const openNorthpassUrl = async () => {
    mixpanel?.track('Opens job tips course', {
      BookingLevel: level.group,
      BookingType: type.type || '',
    })

    await openURLIfCan(northpassUrls.bookingConfirmedSEUrl)
  }

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.scrollContent, ...globalStyles.containerSize }}
      testID="scrollView"
      showsVerticalScrollIndicator={false}
      ref={scrollRef}
      refreshControl={
        refetch ? (
          <RefreshControl
            colors={[colors.blue._400, colorsNew.redesign.text.primary]}
            onRefresh={refetch}
            refreshing={!!loading}
            tintColor={colorsNew.redesign.text.primary}
            titleColor={colors.blue._400}
          />
        ) : undefined
      }
    >
      {children}
      {healthInformation ? (
        <View style={styles.alert}>
          <AlertCard
            title={translate((translations as TranslationGBT).jobOfferHealthTitle)}
            colorSchema={alertColorScheme.warning}
            variant={alertVariants.standard}
            description={healthInformation}
          />
        </View>
      ) : null}

      <View style={showMask ? styles.mask : undefined}>
        <OfferHeader {...jobHeader} scrollToBookingList={scrollToBookings} />
        <OfferInfo
          competences={competences}
          jobGrade={jobGrade}
          jobLevel={jobLevel}
          jobRoles={jobRoles}
          jobTitle={jobTitle}
        />

        {schoolData ? <SchoolNavigationRow schoolData={schoolData} level={level} /> : null}

        {bookingsList?.length ? (
          <>
            <View
              style={styles.divider}
              onLayout={({ nativeEvent }: LayoutChangeEvent) => setBookingListPosition(nativeEvent?.layout.y)}
            />
            <BookingsList bookingsList={bookingsList} />
          </>
        ) : null}

        <View style={styles.divider} />
        <Text style={styles.sectionTitle}>{translate(translations.jobOfferInformationTitle)}</Text>
        <ExpandableText fullText={jobInformation} maxLines={6} />

        {jobFiles
          ? jobFiles?.map(file => <DownloadDocument name={file.name} key={file.id} url={file.url} withIcon={false} />)
          : null}

        <View style={styles.divider} />

        {payRate ? (
          <InfoRow Icon={PaymentsIcon} title={translate(translations.jobOfferPayTitle)} description={payRate} />
        ) : null}

        {unpaidBreakTime ? (
          <InfoRow
            Icon={FreeBreakfastRoundedIcon}
            title={translate(translations.jobOfferBreakTitle)}
            description={unpaidBreakTime?.toString()}
          />
        ) : null}

        {originalTeacher ? (
          <InfoRow
            Icon={PersonRoundedIcon}
            title={translate(translations.jobOfferReplacementTitle)}
            description={originalTeacher}
          />
        ) : null}

        {SENSkills ? (
          <InfoRow
            Icon={PsychologyRoundedIcon}
            title={translate((translations as TranslationGBT).SENSkills)}
            description={SENSkills}
          />
        ) : null}

        {pupilInfo ? (
          <InfoRow
            Icon={AssignmentIndIcon}
            title={translate((translations as TranslationGBT).pupilHeader)}
            description={pupilInfo}
            files={pupilFiles}
          />
        ) : null}

        {room ? (
          <InfoRow Icon={HouseRoundedIcon} title={translate(translations.jobOfferRoomTitle)} description={room} />
        ) : null}

        {lunchPrice ? (
          <InfoRow
            Icon={RestaurantRoundedIcon}
            title={translate(translations.jobOfferLunchTitle)}
            description={lunchPrice}
          />
        ) : null}

        <View style={styles.divider} />

        {isNorthpassButtonDisplay && level?.title && (
          <>
            <RedirectRow
              Icon={SlideShowIcon}
              onPress={openNorthpassUrl}
              subtitle={translate(translations.confirmedNorthpassButtonDescription)}
              title={translate(translations.confirmedNorthpassButtonText, { level: level.title.toLowerCase() })}
            />
            <View style={styles.divider} />
          </>
        )}

        <ContactDetailsRow />
      </View>
    </ScrollView>
  )
}

export default JobDetails
